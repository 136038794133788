import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ margin: '40px 0', textAlign: 'center' }}>
      <h1>Page not found</h1>
      <p>The requested page could not be found.</p>
      <p>Explore our <Link to='/sitemap'>sitemap</Link> or <Link to='/contact'>contact us</Link> to request the information you need.</p>
    </div>
  </Layout>
)

export default NotFoundPage
